/* Global variables declared here... */

/* variables */

$primary-clr-dark: #232f3e;
// $primary-clr-alpha: #FF9900;
$primary-clr-alpha: RGB(0, 169, 225);
$bg-clr: #f3f3f3;
$bg-clr-alpha: #edeced;
$primary-ft-clr: #595a5c;
$primary-grey: #4c4b4c;
$white: #fff;
$black: #333;
$primary-font: "Amazon Ember", sans-serif;
$ft-thin: 300;
$ft-regular: 400;
$ft-bold: 700;

/* MIXINS */

/* mixin for border radius */

@mixin border-radius($top, $right, $bottom, $left) {
  -webkit-border-radius: $top $right $bottom $left;
  -moz-border-radius: $top $right $bottom $left;
  border-radius: $top $right $bottom $left;
}

/* to set the image */
@mixin set-image-config($width, $height, $cursor, $margin-right) {
  width: $width;
  height: $height;
  cursor: $cursor;
  margin-right: $margin-right;
}

@mixin border-single-radius($rad) {
  -webkit-border-radius: $rad;
  -moz-border-radius: $rad;
  border-radius: $rad;
}

/* box-shadow generator
    $clr- in RGBA format
 */

@mixin box-shadows($hset, $vset, $blur, $spread, $clr) {
  -webkit-box-shadow: $hset $vset $blur $spread $clr;
  -moz-box-shadow: $hset $vset $blur $spread $clr;
  box-shadow: $hset $vset $blur $spread $clr;
}

/* mixin for create buttons */

@mixin create-buttons($bgClr,
  $clr,
  $ptb,
  $plr,
  $width,
  $height,
  $ft-size,
  $ft-weight,
  $rad) {
  display: inline-block;
  padding: $ptb $plr;
  margin-bottom: 0;
  line-height: calc($height/2);
  text-align: left;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  @include border-single-radius($rad);
  font-size: $ft-size;
  font-weight: $ft-weight;
  height: $height;
  width: $width;
  background: $bgClr;
  color: $clr;
  position: relative;
  outline: none;

  &:focus,
  // &:active,
  &:hover {
    background: $clr;
    color: $bgClr;
    border: 1px solid $bgClr;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    text-decoration: none;
  }
}

@mixin no-transition($bg-color, $color) {

  &:hover,
  &:focus,
  &:active {
    background: $bg-color;
    color: $color;
    border: 1px solid $bg-color;
  }
}

/* generate margin & padding */

@mixin generate-margin($tp, $rt, $bt, $lt) {
  margin: $tp $rt $bt $lt;
}

@mixin generate-padding($tp, $rt, $bt, $lt) {
  padding: $tp $rt $bt $lt;
}

/*
remove the autofilled background color
*/
@mixin remove-autofill-background() {

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition-delay: 99999s;
    -webkit-transition-delay: 99999s;
  }
}

/* Common/Fixed Styles - start*/
.pl-20 {
  padding-left: 20px;
}

.ml-10 {
  margin-left: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.t_height {
  height: 400px;
}

.l_td {

  td,
  th {
    font-size: 14px;
  }
}

.my-custom-filter-class {
  width: 30% !important;
  padding-top: 38px;

  mat-dialog-container {
    border-radius: 0 !important;
  }
}

/* Common/Fixed Styles - end*/
::ng-deep .mat-calendar-body-selected {
  background-color: $primary-clr-alpha !important;
}

::ng-deep .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom: none !important;
}

::ng-deep .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom: none !important;
}

::ng-deep .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  background-color: $primary-clr-alpha !important;
}

::ng-deep .mat-form-field {
  border: none !important;
}

::ng-deep .mat-mdc-form-field-icon-suffix,
[dir="rtl"] .mat-mdc-form-field-icon-prefix {
  width: 30px !important;
  margin-top: 10px;
}

::ng-deep .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  align-items: center;
  border-radius: 5px;
}

::ng-deep .mat-mdc-icon-button {
  --mat-icon-button-hover-state-layer-opacity: 0 !important;
}

::ng-deep .mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
  display: none !important;
}

::ng-deep .mat-date-range-input-wrapper,
::ng-deep .mat-date-range-input-end-wrapper {
  width: 110px;
}

::ng-deep .mat-mdc-form-field-type-mat-date-range-input .mat-mdc-form-field-infix {
  width: 220px !important;
}

::ng-deep mat-datepicker-toggle {
  background-image: url(../images/calendar-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  button {
    width: 20px !important;
  }

  .mat-mdc-icon-button svg {
    display: none !important;
  }
}

::ng-deep .mat-date-range-input-separator {
  padding: 10px;
}

::ng-deep .mat-date-range-input-container {
  width: 220px;
  justify-content: center;
  font-family: "Poppins";
}

::ng-deep .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  border: 1px solid $primary-clr-alpha !important;
  margin-right: 4px;
  background: $primary-clr-alpha !important;
}

::ng-deep .multiselect-item-checkbox input[type="checkbox"]+div:before {
  border-color: $primary-clr-alpha !important;
}

::ng-deep .multiselect-item-checkbox[_ngcontent-ng-c1411523848] input[type="checkbox"][_ngcontent-ng-c1411523848]:checked+div[_ngcontent-ng-c1411523848]:before {
  background: $primary-clr-alpha !important;
}

::ng-deep .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: transparent !important;
  display: none !important;
}

::ng-deep .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: transparent !important;
}

.cancel {
  margin-right: 20px;
}

::ng-deep .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  height: 30px;
  background-color: transparent !important;
  display: flex;
  align-items: center;
  border-bottom: 1px solid;

  &:focus {
    background-color: transparent !important;
  }
}

::ng-deep .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: none !important;
}

::ng-deep .mat-chip-list-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 30px;
}

::ng-deep mat-chip {
  background: white !important;
  display: flex;
  align-items: center;
  border-radius: 0px !important;
}

::ng-deep .mat-mdc-standard-chip .mdc-evolution-chip__cell--primary {
  justify-content: center !important;
}

::ng-deep .mdc-checkbox__ripple {
  --mdc-checkbox-selected-checkmark-color: $white !important;
  --mdc-checkbox-selected-icon-color: $primary-clr-alpha !important;
}

::ng-deep mat-snack-bar-container {
  margin-top: 80px !important;
}

::ng-deep .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  padding: 20px 10px !important;
}

::ng-deep .mat-mdc-form-field-icon-prefix {
  margin-bottom: 0px !important;
}

::ng-deep .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-handle-color: RGB(0, 169, 225) !important;
  --mdc-switch-selected-hover-handle-color: RGB(0, 169, 225) !important;
  --mdc-switch-selected-hover-track-color: RGB(0, 169, 225) !important;
  --mdc-switch-selected-track-color: RGB(0, 169, 225) !important;

  --mdc-switch-selected-focus-state-layer-color: RGB(0, 169, 225) !important;
  --mdc-switch-selected-handle-color: RGB(0, 169, 225) !important;
  --mdc-switch-selected-hover-state-layer-color: RGB(0, 169, 225) !important;
  --mdc-switch-selected-pressed-state-layer-color: RGB(0, 169, 225) !important;
  --mdc-switch-selected-focus-handle-color: RGB(0, 169, 225) !important;
  --mdc-switch-selected-hover-handle-color: RGB(0, 169, 225) !important;
  --mdc-switch-selected-pressed-handle-color: RGB(0, 169, 225) !important;
  --mdc-switch-selected-focus-track-color: RGB(0, 169, 225) !important;
  --mdc-switch-selected-hover-track-color: RGB(0, 169, 225) !important;
  --mdc-switch-selected-pressed-track-color: RGB(0, 169, 225) !important;
}

::ng-deep html {
  --mdc-checkbox-selected-icon-color: RGB(0, 169, 225) !important;
  --mdc-checkbox-selected-checkmark-color: #fff !important;
  --mdc-checkbox-selected-hover-icon-color: RGB(0, 169, 225) !important;
  --mdc-checkbox-selected-focus-icon-color: RGB(0, 169, 225) !important;
  --mdc-checkbox-selected-pressed-icon-color: RGB(0, 169, 225) !important;
  --mdc-checkbox-selected-focus-state-layer-color: RGB(0, 169, 225) !important;
}

::ng-deep .mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-icon-color: RGB(0, 169, 225) !important;
  --mdc-radio-selected-pressed-icon-color: RGB(0, 169, 225) !important;
  --mdc-radio-selected-focus-icon-color: RGB(0, 169, 225) !important;
  --mdc-radio-selected-hover-icon-color: RGB(0, 169, 225) !important;
  --mat-radio-checked-ripple-color: RGB(0, 169, 225) !important;
}

::ng-deep .mdc-text-field .mat-form-field-focus-overlay {
  &:hover {
    background: transparent !important;
  }
}

::ng-deep .mat-mdc-select-value-text {
  font-family: "Amazon Ember", sans-serif !important;
}

table.table.table-bordered tbody tr td,
tr th {
  font-size: 16px !important;
}

::ng-deep .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: $primary-clr-alpha !important;
}

::ng-deep mat-pseudo-checkbox {
  display: none !important;
}

::ng-deep .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  border-radius: 0px;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

.dropdown-item:active {
  background: none;
}

.td-center {

  td,
  th {
    text-align: center;
  }
}

.f-14 {
  font-size: 14px !important;
}