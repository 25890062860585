/* You can add global styles to this file, and also import other style files */
@import "./assets/style/global.scss";

@font-face {
  font-family: "Amazon Ember Display Light";
  src: url("./assets/fonts/amazon_light.eot");
  src: url("./assets/fonts/amazon_light.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/amazon_light.woff2") format("woff2"),
    url("./assets/fonts/amazon_light.woff") format("woff"),
    url("./assets/fonts/amazon_light.svg") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Amazon Ember";
  src: url("./assets/fonts/amazon_regular.eot");
  src: url("./assets/fonts/amazon_regular.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/amazon_regular.woff2") format("woff2"),
    url("./assets/fonts/amazon_regular.woff") format("woff"),
    url("./assets/fonts/amazon_regular.svg") format("svg");
  font-weight: 400;
  font-style: normal;
}

html {
  height: 100%;
}

body {
  font-family: $primary-font;
  background: $bg-clr;
  min-height: 100%;
  line-height: 1.5;
  font-weight: $ft-regular;
  font-size: 14px;
  position: relative;
  color: $primary-ft-clr;
  transition-duration: 0.5s; // overflow: hidden;
  transition-timing-function: ease; // cursor: pointer;
  // direction: rtl;
  overflow: hidden;
}

button {
  &:disabled {
    cursor: not-allowed;
  }
}

.primary-clr {
  color: $primary-clr-alpha !important;
}

/* global material change */
.block-input {
  width: 100%;
  max-width: 300px;
  display: block;
}

.form_fluid {
  display: flex;
  flex-direction: column;
}

input.mat-input-element,
.mat-form-field {
  caret-color: $primary-clr-alpha !important;

  &:disabled {
    cursor: not-allowed;
  }
}

.keywords-container {
  .mat-chip:not(.mat-basic-chip) {
    border-radius: 5px !important;
    background-color: #fcfafc !important;
    font-size: 14px;
    padding: 7px 10px !important;
    margin: 0 20px 10px 0 !important;
    min-height: 40px;

    &:active {
      box-shadow: none !important;
    }
  }

  mat-chip pre {
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;
    background: none !important;
    font-size: 14px;
    font-family: "Amazon Ember", sans-serif;
  }
}

label,
input,
.mat-form-field-label,
.mat-checkbox-layout .mat-checkbox-label,
.mat-stepper-horizontal {
  color: $primary-clr-dark !important;
  font-family: $primary-font !important;
}

.mat-focused .mat-form-field-required-marker {
  color: #ff0000 !important;
}

.mat-input-element {
  line-height: 1.3 !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #00a9e1;
}

.mat-form-field-underline,
.mat-form-field-ripple,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background: $primary-clr-dark !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background: #0892e2 !important;
}

// change the ripple color for checkbox and radio button
.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element,
.mat-mdc-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element // .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,

// .mat-checkbox-checked.mat-accent .mat-checkbox-background,
// .mat-primary .mat-pseudo-checkbox-checked
  {
  opacity: 0.5;
  background-color: $primary-clr-alpha;
}

// .mat-checkbox.mat-accent .mat-checkbox-ripple .mat-ripple-element {
//   opacity: 0.3 !important;     __<<ngThemingMigrationEscapedComment2>>__
//   background-color: $primary-clr-alpha !important;
// }
.mat-step-header .mat-step-icon {
  color: $white;
  background: $primary-clr-dark;
}

.mat-step-header:hover {
  background-color: initial !important;
}

.mat-step-label {
  font-size: 18px;
}

.mat-form-field-flex {
  .mat-form-field-infix {
    width: 230px;
  }
}

.mat-dialog-container {
  padding: 0px !important;
  @include border-single-radius(10px !important);
}

.mat-mdc-radio-button {
  &.mat-accent {
    &.mat-mdc-radio-checked {
      .mdc-radio__outer-circle {
        border-color: $primary-clr-dark;
      }
    }
  }
}

.mat-mdc-radio-button {
  &.mat-accent {
    .mdc-radio__inner-circle {
      background-color: $primary-clr-dark;
    }
  }
}

.mat-snack-bar-container {
  z-index: 99999;
  margin-top: 77px !important;
}

.mat-error {
  .mat-hint {
    color: red !important;
    font-size: 12px !important;
    font-family: $primary-font !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

.mat-simple-snackbar {
  font-family: $primary-font !important;
  text-transform: capitalize;
}

.mat-slide-toggle {
  &.mat-checked:not(.mat-disabled) {
    .mat-slide-toggle-thumb {
      background: $primary-clr-alpha;
    }
  }
}

.mat-slide-toggle {
  &.mat-checked:not(.mat-disabled) {
    .mat-slide-toggle-bar {
      background: $primary-clr-dark;
    }
  }
}

mat-form-field {
  .mat-mdc-button-ripple {
    width: 40px;
    height: 40px;
  }

  .mat-mdc-icon-button {
    width: 40px !important;
    height: 40px !important;
  }

  .mat-mdc-form-field-icon-suffix {
    margin: 0;
    display: flex;
    transform: translate(4px, 0px);
    width: 40px !important;
  }
}

.dasboard-panel {
  .mat-expansion-panel-header {
    padding: 0 10px !important;
  }

  .mat-expansion-panel-body {
    padding: 0 10px 16px !important;
  }

  .mat-expansion-panel-header-description {
    display: inline-block !important;
    margin-right: 0;
  }

  // .mat-expansion-panel-header-description{
  //   display: block !important;
  // }
}

// status modal
.status-section-custom-dialog {
  .mat-dialog-container {
    border-radius: 16px !important;
    width: 550px;
  }
}

// status modal
.status-section-custom-dialog {
  .mat-dialog-container {
    border-radius: 16px !important;
    width: 550px;
  }
}

/* date range picker */
.ranges {
  li {
    font-size: 16px !important;
    color: $primary-clr-dark !important;
    background: none !important;
    border: none !important;

    &.active,
    &:hover {
      background: $primary-clr-alpha !important;
      color: $white !important;
      border: none !important;
    }
  }

  .range_inputs {
    .applyBtn {
      @include create-buttons($primary-clr-alpha !important,
        $white !important,
        6px,
        12px,
        49%,
        35px,
        16px,
        $ft-regular,
        5px);
      text-align: center;
    }

    .cancelBtn {
      @include create-buttons($bg-clr-alpha !important,
        $primary-clr-dark !important,
        6px,
        12px,
        49%,
        35px,
        16px,
        $ft-regular,
        5px);
      text-align: center;
    }
  }
}

.daterangepicker {
  td {
    color: $primary-clr-dark !important;

    &.active,
    &:hover {
      background: $primary-clr-alpha !important;
      color: $white !important;
    }
  }
}

.selected-list {
  .c-list {
    .c-token {
      background: $primary-clr-alpha !important;
      color: $white !important;
    }
  }
}

.pure-checkbox input[type="checkbox"]:checked+label:before,
.pure-checkbox input[type="checkbox"]+label:before {
  background: $primary-clr-dark !important;
  color: $primary-clr-alpha !important;
  border-color: $primary-clr-dark !important;
}

.pure-checkbox input[type="checkbox"]+label:before {
  background: $white !important;
}

/* global bootstrap change */
.no-gutter {
  margin-right: 0;
  margin-left: 0;

  [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

// to make the sort icon blur
.sort-blur {
  opacity: 0.2;
}

.sort-section {
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;

  img:first-child {
    margin-right: 2px !important;
  }
}

/* Global styles */
table {
  &.table {
    &.table-bordered {
      thead {
        tr {
          background: $bg-clr-alpha;

          th {
            font-size: 16px;
            color: $primary-clr-dark;
            font-weight: $ft-regular;
            background: transparent;
            border: none;
            border-bottom: 1px solid #ddd;
            vertical-align: middle;

            &:last-child {
              text-align: center;
            }

            @media (min-width: 1280px) and (max-width: 1440px) {
              font-size: 14px !important;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            font-size: 16px;
            font-weight: $ft-thin;
            color: $primary-ft-clr;
            border: none;
            border-bottom: 1px solid #ddd;

            &:last-child {
              width: 15%;
              text-align: center;
            }

            @media (min-width: 1280px) and (max-width: 1440px) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

// to give a scroll to table body
.table-scroll {
  border: 1px solid #ddd;

  thead,
  tbody tr,
  tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  th,
  td {
    border: none;
    border-bottom: 1px solid #ddd !important;
    font-size: 16px;
  }

  thead tr {
    background: #edeced;

    th {
      color: #232f3e;
      font-weight: 400;
      background: transparent;
      vertical-align: middle;
    }
  }

  tbody {
    display: block;
    max-height: 62vh;
    overflow-y: overlay;

    // overflow: auto;
    tr:last-child td {
      border-bottom: none !important;
    }

    td {
      border-top: none !important;
      word-break: break-word;
      word-break: break-all;
      font-weight: 300;
      color: #595a5c;
      // vertical-align: middle;
    }
  }
}

/** Styles specific to mozilla */
@-moz-document url-prefix() {
  .table-scroll {
    border: none;

    thead tr,
    tbody tr {
      border: 1px solid #ddd;
    }

    thead {
      width: calc(100% - 1em);

      tr {
        border-bottom: none !important;
      }
    }

    tbody {
      overflow-y: scroll !important;

      tr {
        border-top: none !important;
      }

      // overflow-x: auto;
    }
  }

  /*scroll bar */
  ::-webkit-scrollbar {
    background: transparent;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }
}

.layout-wrap>div {
  padding-bottom: 40px !important;
}

.attach-icon {
  .material-icons {
    vertical-align: bottom !important;
  }
}

/*loader*/
.loader-overlay {
  position: fixed;
  color: $primary-clr-alpha;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $primary-clr-dark;
  z-index: 9999;

  .loader-table {
    display: table;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .loader-tablecell {
    display: table-cell;
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }
}

/* Global buttons */
.cancel {
  @include create-buttons($bg-clr-alpha !important,
    $primary-clr-dark !important,
    6px,
    12px,
    auto,
    35px,
    16px,
    $ft-regular,
    5px);
  border-color: #b5b5b5;
  text-decoration: none;
}

.save {
  @include create-buttons($primary-clr-alpha !important,
    $white !important,
    6px,
    12px,
    auto,
    35px,
    16px,
    $ft-regular,
    5px);
}

.display-ib {
  display: inline-block;
}

.cursor-pointer {
  cursor: pointer !important;
}

.mark-bg {
  background-color: #a4e8ff !important;
  padding: 0 2px;
}

.edit_button,
.delete_button {
  width: 25px;
  height: 25px;
  display: inline-block;
  margin-right: 30px;
  cursor: pointer;

  // &:hover {
  //   background-position: 0px -25px;
  // }
}

.edit_button {
  background: url("./assets/images/edit.png") scroll 0 0 no-repeat;
}

.delete_button {
  background: url("./assets/images/delete.png") scroll 0 0 no-repeat;
  margin-right: 0px;
}

.reset_button,
.view_button,
.download_button {
  display: inline-block;
  // margin-left: 30px;
  cursor: pointer;
  width: 25px;
  height: 25px;

  img {
    display: block;
  }
}

.download_button {
  margin-right: 30px;
}

.my-pagination {
  // float: right;
  display: flex;
  justify-content: flex-end;
  row-gap: 10px;
  align-items: center;
  padding: 15px 0;

  .mr {
    margin-right: 10px;
  }

  .form-control {
    width: 75px !important;
    box-shadow: none;

    &:active,
    &:focus {
      border-color: #ddd;
      outline: none;
      box-shadow: none;
    }
  }

  pagination-controls {
    display: inline-block;

    .ngx-pagination {
      margin-bottom: 0;
      padding-left: 0;
    }
  }

  li {
    &.current {
      background-color: $primary-clr-dark;
      color: $white;
    }

    a {

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
    }
  }

  @media (max-width: 592px) {
    flex-direction: column-reverse;
    row-gap: 15px;
  }
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 99999999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .keywords-container {
    .mat-chip:not(.mat-basic-chip) {
      font-size: 14px;
      max-height: 35px;
    }

    .keywords-list {
      img {
        width: 26px !important;
        height: 26px !important;
        margin-left: 15px !important;
      }
    }
  }

  .my-pagination {
    .mr {
      margin-right: 10px !important;
    }

    .form-control {
      width: 100% !important;
    }
  }
}

.mat-mdc-radio-button.mat-accent .mdc-radio__inner-circle {
  background: none;
}

.mat-mdc-radio-button.mat-accent:not(.mat-mdc-radio-checked) .mdc-radio__inner-circle {
  display: none;
}

.mat-mdc-radio-checked .mdc-radio__inner-circle {
  display: inline-block;
  transform: rotate(40deg) !important;
  height: 16px;
  width: 8px;
  margin-left: 30%;
  border-style: none;
  border-bottom: 3px solid #ffffff !important;
  border-right: 3px solid #ffffff !important;
  border-radius: 0px;
  background-color: white;
}

.mdc-radio__outer-circle {
  border-radius: 0px !important;
}

.mat-mdc-radio-checked .mdc-radio__outer-circle {
  border-radius: 0px !important;
  background-color: #0892e2 !important;
  border-width: 0px !important;
}

.test:after {
  content: "\2807";
  font-size: 28px;
  color: #969696;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

// .flex-item {
//   float: left;
//   margin-top: 5px;
// }

.dropdown-menu {
  padding: 4px 0 !important;
  position: absolute;
  top: 44px;
  right: 4px;
}

.dropdown-item {
  font-size: 14px;

  &:active {
    color: black !important;
  }

  &:hover {
    background-color: #f0f2f3 !important;
  }
}

// Scrollbar 
div,
tbody {
  &::-webkit-scrollbar {
    appearance: none;
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d3d3d3;
    border-radius: 5px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a9a9a9; // Change the color on hover
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: #9b9b9b; // Change the color on hover
  }
}

// ::ng-deep .mat-mdc-form-field-infix {
//   position: static !important;
// }

// input {
//   width: 100%;
//   // padding: 4px;
//   // margin-bottom: 10px;
//   margin-top: 8px;
//   box-sizing: border-box;
// }

// mat-select {
//   margin-top: 8px;
// }

mat-form-field {
  width: 300px;
  height: 75px;
}


@media (max-width: 364px) {

  .w-xs-100 {
    width: 100% !important;
  }
}